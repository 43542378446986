export const MyCases = 'My cases';
export const CcCases = 'CC cases';

export enum ECaseStatus {
  Submitted = 'Submitted',
  Active = 'Active',
  Resolved = 'Resolved',
  Closed = 'Closed',
  Deleted = 'Deleted',
  Reopened = 'Reopened',
}

export const sspList = [
  'AdX/GAM (181)',
  'Magnite-Telaria/Hulu (3332)',
  'Freewheel (3053)',
  'SpotX (3014)',
]

export const countryList = [
  { value: '1', text: 'Albania' },
  { value: '2', text: 'Antarctica' },
  { value: '3', text: 'Algeria' },
  { value: '4', text: 'American Samoa' },
  { value: '5', text: 'Andorra' },
  { value: '6', text: 'Angola' },
  { value: '7', text: 'Antigua and Barbuda' },
  { value: '8', text: 'Argentina' },
  { value: '9', text: 'Australia' },
  { value: '10', text: 'Austria' },
  { value: '11', text: 'Bahamas, The' },
  { value: '12', text: 'Bangladesh' },
  { value: '13', text: 'Barbados' },
  { value: '14', text: 'Belgium' },
  { value: '15', text: 'Bermuda' },
  { value: '16', text: 'Bhutan' },
  { value: '17', text: 'Bolivia' },
  { value: '18', text: 'Botswana' },
  { value: '19', text: 'Bouvet Island' },
  { value: '20', text: 'Brazil' },
  { value: '21', text: 'Belize' },
  { value: '22', text: 'British Indian Ocean Territory' },
  { value: '23', text: 'Solomon Islands' },
  { value: '24', text: 'Virgin Islands, British' },
  { value: '25', text: 'Brunei' },
  { value: '26', text: 'Bulgaria' },
  { value: '27', text: 'Myanmar' },
  { value: '28', text: 'Burundi' },
  { value: '29', text: 'Belarus' },
  { value: '30', text: 'Cambodia' },
  { value: '31', text: 'Cameroon' },
  { value: '32', text: 'Canada' },
  { value: '33', text: 'Cape Verde' },
  { value: '34', text: 'Cayman Islands' },
  { value: '35', text: 'Central African Republic' },
  { value: '36', text: 'Sri Lanka' },
  { value: '37', text: 'Chad' },
  { value: '38', text: 'Chile' },
  { value: '39', text: 'China' },
  { value: '40', text: 'Taiwan' },
  { value: '41', text: 'Christmas Island' },
  { value: '42', text: 'Cocos (Keeling), Islands' },
  { value: '43', text: 'Colombia' },
  { value: '44', text: 'Mayotte' },
  { value: '45', text: 'Congo' },
  { value: '46', text: 'Congo (DRC)' },
  { value: '47', text: 'Cook Islands' },
  { value: '48', text: 'Costa Rica' },
  { value: '49', text: 'Croatia' },
  { value: '51', text: 'Czech Republic' },
  { value: '52', text: 'Benin' },
  { value: '53', text: 'Denmark' },
  { value: '54', text: 'Dominica' },
  { value: '55', text: 'Dominican Republic' },
  { value: '56', text: 'Ecuador' },
  { value: '57', text: 'El Salvador' },
  { value: '58', text: 'Equatorial Guinea' },
  { value: '59', text: 'Ethiopia' },
  { value: '60', text: 'Eritrea' },
  { value: '61', text: 'Estonia' },
  { value: '62', text: 'Faroe Islands' },
  { value: '63', text: 'Falkland Islands' },
  { value: '64', text: 'South Georgia and the South Sandwich Islands' },
  { value: '65', text: 'Finland' },
  { value: '66', text: 'France' },
  { value: '67', text: 'French Guiana' },
  { value: '68', text: 'French Polynesia' },
  { value: '69', text: 'Djibouti' },
  { value: '70', text: 'Gabon' },
  { value: '71', text: 'Gambia, The' },
  { value: '72', text: 'Germany' },
  { value: '73', text: 'Ghana' },
  { value: '74', text: 'Gibraltar' },
  { value: '75', text: 'Kiribati' },
  { value: '76', text: 'Greece' },
  { value: '77', text: 'Greenland' },
  { value: '78', text: 'Grenada' },
  { value: '79', text: 'Guadeloupe' },
  { value: '80', text: 'Guam' },
  { value: '81', text: 'Guatemala' },
  { value: '82', text: 'Guinea' },
  { value: '83', text: 'Guyana' },
  { value: '84', text: 'Haiti' },
  { value: '85', text: 'Heard Island and McDonald Islands' },
  { value: '86', text: 'Vatican City' },
  { value: '87', text: 'Honduras' },
  { value: '88', text: 'Hungary' },
  { value: '89', text: 'Iceland' },
  { value: '90', text: 'India' },
  { value: '91', text: 'Indonesia' },
  { value: '92', text: 'Ireland' },
  { value: '93', text: 'Italy' },
  { value: '94', text: 'Côte d Ivoire' },
  { value: '95', text: 'Jamaica' },
  { value: '96', text: 'Japan' },
  { value: '97', text: 'Kazakhstan' },
  { value: '98', text: 'Kenya' },
  { value: '100', text: 'Korea' },
  { value: '101', text: 'Kyrgyzstan' },
  { value: '102', text: 'Laos' },
  { value: '103', text: 'Lesotho' },
  { value: '104', text: 'Latvia' },
  { value: '105', text: 'Liberia' },
  { value: '106', text: 'Libya' },
  { value: '107', text: 'Liechtenstein' },
  { value: '108', text: 'Lithuania' },
  { value: '109', text: 'Luxembourg' },
  { value: '110', text: 'Madagascar' },
  { value: '111', text: 'Malawi' },
  { value: '112', text: 'Malaysia' },
  { value: '113', text: 'Maldives' },
  { value: '114', text: 'Mali' },
  { value: '115', text: 'Malta' },
  { value: '116', text: 'Martinique' },
  { value: '117', text: 'Mauritania' },
  { value: '118', text: 'Mauritius' },
  { value: '119', text: 'Mexico' },
  { value: '120', text: 'Monaco' },
  { value: '121', text: 'Mongolia' },
  { value: '122', text: 'Republic of Moldova' },
  { value: '123', text: 'Montserrat' },
  { value: '124', text: 'Morocco' },
  { value: '125', text: 'Mozambique' },
  { value: '126', text: 'Namibia' },
  { value: '127', text: 'Nauru' },
  { value: '128', text: 'Nepal' },
  { value: '129', text: 'Netherlands, The' },
  { value: '130', text: 'Bonaire, Curaçao, Saba, Sint Eustatius, Sint Maarten' },
  { value: '131', text: 'Aruba' },
  { value: '132', text: 'New Caledonia' },
  { value: '133', text: 'Vanuatu' },
  { value: '134', text: 'New Zealand' },
  { value: '135', text: 'Nicaragua' },
  { value: '136', text: 'Niger' },
  { value: '137', text: 'Nigeria' },
  { value: '138', text: 'Norfolk Island' },
  { value: '139', text: 'Norway' },
  { value: '140', text: 'Northern Mariana Islands' },
  { value: '141', text: 'Micronesia' },
  { value: '142', text: 'Marshall Islands' },
  { value: '143', text: 'Palau' },
  { value: '144', text: 'Pakistan' },
  { value: '145', text: 'Panama' },
  { value: '146', text: 'Papua New Guinea' },
  { value: '147', text: 'Paraguay' },
  { value: '148', text: 'Peru' },
  { value: '149', text: 'Philippines' },
  { value: '150', text: 'Pitcairn Islands' },
  { value: '151', text: 'Poland' },
  { value: '152', text: 'Portugal' },
  { value: '153', text: 'Guinea-Bissau' },
  { value: '154', text: 'Puerto Rico' },
  { value: '155', text: 'Reunion' },
  { value: '156', text: 'Russia' },
  { value: '157', text: 'Rwanda' },
  { value: '158', text: 'Anguilla' },
  { value: '159', text: 'San Marino' },
  { value: '160', text: 'Sao Tomé and Príncipe' },
  { value: '161', text: 'Senegal' },
  { value: '162', text: 'Seychelles' },
  { value: '163', text: 'Sierra Leone' },
  { value: '164', text: 'Singapore' },
  { value: '165', text: 'Slovakia' },
  { value: '166', text: 'Vietnam' },
  { value: '167', text: 'Slovenia' },
  { value: '168', text: 'South Africa' },
  { value: '169', text: 'Zimbabwe' },
  { value: '170', text: 'Spain' },
  { value: '173', text: 'Suriname' },
  { value: '174', text: 'Swaziland' },
  { value: '175', text: 'Sweden' },
  { value: '176', text: 'Switzerland' },
  { value: '177', text: 'Tajikistan' },
  { value: '178', text: 'Thailand' },
  { value: '179', text: 'Togo' },
  { value: '180', text: 'Tokelau' },
  { value: '181', text: 'Tonga' },
  { value: '182', text: 'Trinidad and Tobago' },
  { value: '183', text: 'Turkey' },
  { value: '184', text: 'Turkmenistan' },
  { value: '185', text: 'Turks and Caicos Islands' },
  { value: '186', text: 'Uganda' },
  { value: '187', text: 'Macedonia, Former Yugoslav Republic of' },
  { value: '188', text: 'Egypt' },
  { value: '189', text: 'United Kingdom' },
  { value: '190', text: 'Tanzania' },
  { value: '191', text: 'United States' },
  { value: '192', text: 'Burkina Faso' },
  { value: '193', text: 'Uruguay' },
  { value: '194', text: 'Uzbekistan' },
  { value: '195', text: 'Wallis and Futuna' },
  { value: '196', text: 'Samoa' },
  { value: '197', text: 'Zambia' },
  { value: '198', text: 'U.S. Minor Outlying Islands' },
  { value: '199', text: 'Bosnia and Herzegovina' },
  { value: '200', text: 'Fiji Islands' },
  { value: '201', text: 'Hong Kong SAR' },
  { value: '202', text: 'Macao SAR' },
  { value: '203', text: 'Venezuela' },
  { value: '204', text: 'Azerbaijan' },
  { value: '205', text: 'Bahrain' },
  { value: '206', text: 'Armenia' },
  { value: '207', text: 'Cyprus' },
  { value: '208', text: 'French Southern and Antarctic Lands' },
  { value: '209', text: 'Georgia' },
  { value: '211', text: 'Israel' },
  { value: '212', text: 'Jordan' },
  { value: '213', text: 'Kuwait' },
  { value: '214', text: 'Lebanon' },
  { value: '215', text: 'Oman' },
  { value: '216', text: 'Qatar' },
  { value: '217', text: 'Saudi Arabia' },
  { value: '219', text: 'United Arab Emirates' },
  { value: '220', text: 'Yemen' },
  { value: '221', text: 'Palestinian Authority' },
  { value: '222', text: 'Afghanistan' },
  { value: '224', text: 'Timor-Leste' },
  { value: '225', text: 'Niue' },
  { value: '226', text: 'Romania' },
  { value: '227', text: 'Somalia' },
  { value: '228', text: 'Comoros' },
  { value: '229', text: 'Tunisia' },
  { value: '230', text: 'Iraq' },
  { value: '231', text: 'Saint Helena' },
  { value: '232', text: 'Saint Kitts and Nevis' },
  { value: '233', text: 'Saint Lucia' },
  { value: '234', text: 'Saint Pierre and Miquelon' },
  { value: '235', text: 'Saint Vincent and the Grenadines' },
  { value: '236', text: 'Tuvalu' },
  { value: '237', text: 'Ukraine' },
  { value: '239', text: 'Virgin Islands, U.S.' },
  { value: '240', text: 'Svalbard' },
  { value: '241', text: 'Cuba' },
  { value: '242', text: 'Iran' },
  { value: '243', text: 'North Korea' },
  { value: '244', text: 'Sudan' },
  { value: '245', text: 'Syria' },
  { value: '246', text: 'Serbia' },
  { value: '247', text: 'Montenegro' }
]

export const timezoneList = [
  { value: '1', text: 'Tonga Standard Time (PHOT)' },
  { value: '2', text: 'Fiji Standard Time (FJT)' },
  { value: '3', text: 'New Zealand Standard Time (NZST)' },
  { value: '4', text: 'Central Pacific Standard Time (MAGT)' },
  { value: '5', text: 'Vladivostok Standard Time (VLAT)' },
  { value: '6', text: 'Tasmania Standard Time (TSAT)' },
  { value: '7', text: 'West Pacific Standard Time (WPST)' },
  { value: '8', text: 'AUS Eastern Standard Time (AEST)' },
  { value: '9', text: 'E. Australia Standard Time (AEST)' },
  { value: '10', text: 'AUS Central Standard Time (ACST)' },
  { value: '11', text: 'Cen. Australia Standard Time (ACST)' },
  { value: '12', text: 'Yakutsk Standard Time (YAKT)' },
  { value: '13', text: 'Korea Standard Time (KST)' },
  { value: '14', text: 'Tokyo Standard Time (JST)' },
  { value: '15', text: 'Taipei Standard Time (TIST)' },
  { value: '16', text: 'W. Australia Standard Time (AWST)' },
  { value: '17', text: 'Singapore Standard Time (SST)' },
  { value: '18', text: 'North Asia East Standard Time (IRKT)' },
  { value: '19', text: 'China Standard Time (CST)' },
  { value: '20', text: 'North Asia Standard Time (KRAT)' },
  { value: '21', text: 'SE Asia Standard Time (HOVT)' },
  { value: '22', text: 'Myanmar Standard Time (MST)' },
  { value: '23', text: 'Sri Lanka Standard Time (SLST)' },
  { value: '24', text: 'Central Asia Standard Time (BTT)' },
  { value: '25', text: 'N. Central Asia Standard Time (NOVT)' },
  { value: '26', text: 'Nepal Standard Time (NPT)' },
  { value: '27', text: 'India Standard Time (IST)' },
  { value: '28', text: 'Pakistan Standard Time (PKT)' },
  { value: '29', text: 'Ekaterinburg Standard Time (YEKT)' },
  { value: '30', text: 'Afghanistan Standard Time (AFT)' },
  { value: '31', text: 'Azerbaijan Standard Time (AZT)' },
  { value: '32', text: 'Arabian Standard Time (AST)' },
  { value: '33', text: 'Iran Standard Time (IRST)' },
  { value: '34', text: 'E. Africa Standard Time (EAT)' },
  { value: '35', text: 'Russian Standard Time (MSK)' },
  { value: '36', text: 'Arab Standard Time (AST)' },
  { value: '37', text: 'Arabic Standard Time (ARST)' },
  { value: '38', text: 'Israel Standard Time (ISST)' },
  { value: '39', text: 'FLE Standard Time (FLET)' },
  { value: '40', text: 'South Africa Standard Time (SAST)' },
  { value: '41', text: 'Egypt Standard Time (EGST)' },
  { value: '42', text: 'E. Europe Standard Time (EET)' },
  { value: '43', text: 'GTB Standard Time (GTBST)' },
  { value: '44', text: 'W. Central Africa Standard Time (WAT)' },
  { value: '45', text: 'Central European Standard Time (CET)' },
  { value: '46', text: 'Romance Standard Time (RST)' },
  { value: '47', text: 'Central Europe Standard Time (CEST)' },
  { value: '48', text: 'W. Europe Standard Time (WEST)' },
  { value: '49', text: 'Morocco Standard Time (MOST)' },
  { value: '50', text: 'GMT Standard Time (GMT)' },
  { value: '51', text: 'Azores Standard Time (AZOST)' },
  { value: '52', text: 'Cape Verde Standard Time (CVT)' },
  { value: '53', text: 'Mid-Atlantic Standard Time (MAT)' },
  { value: '54', text: 'E. South America Standard Time (BRT)' },
  { value: '55', text: 'Argentina Standard Time (ART)' },
  { value: '56', text: 'Greenland Standard Time (WGT)' },
  { value: '57', text: 'Newfoundland Standard Time (NST)' },
  { value: '58', text: 'Atlantic Standard Time (AST)' },
  { value: '59', text: 'SA Western Standard Time (VET)' },
  { value: '60', text: 'Pacific SA Standard Time (VET)' },
  { value: '61', text: 'SA Pacific Standard Time (PET)' },
  { value: '62', text: 'Eastern Standard Time (EST)' },
  { value: '63', text: 'US Eastern Standard Time (EST)' },
  { value: '64', text: 'Central America Standard Time (CST)' },
  { value: '65', text: 'Central Standard Time (CST)' },
  { value: '66', text: 'Central Standard Time (Mexico) (CST)' },
  { value: '67', text: 'Canada Central Standard Time (CCST)' },
  { value: '68', text: 'US Mountain Standard Time (MST)' },
  { value: '69', text: 'Mountain Standard Time (Mexico) (MST)' },
  { value: '70', text: 'Mountain Standard Time (MST)' },
  { value: '71', text: 'Pacific Standard Time (PT)' },
  { value: '72', text: 'Alaskan Standard Time (AKST)' },
  { value: '73', text: 'Hawaiian Standard Time (HST)' },
  { value: '74', text: 'Samoa Standard Time (SST)' },
  { value: '75', text: 'Dateline Standard Time (IDL)' }
]

export const currencyList = [
  'AED',
  'AFN',
  'ALL',
  'AMD',
  'ANG',
  'AOA',
  'ARS',
  'AUD',
  'AWG',
  'AZN',
  'BAM',
  'BBD',
  'BDT',
  'BGN',
  'BHD',
  'BIF',
  'BMD',
  'BND',
  'BOB',
  'BOV',
  'BRL',
  'BSD',
  'BTN',
  'BWP',
  'BYN',
  'BZD',
  'CAD',
  'CDF',
  'CHE',
  'CHF',
  'CHW',
  'CLF',
  'CLP',
  'CNY',
  'COP',
  'COU',
  'CRC',
  'CUC',
  'CUP',
  'CVE',
  'CZK',
  'DJF',
  'DKK',
  'DOP',
  'DZD',
  'EGP',
  'ERN',
  'ETB',
  'EUR',
  'FJD',
  'FKP',
  'GBP',
  'GEL',
  'GHS',
  'GIP',
  'GMD',
  'GNF',
  'GTQ',
  'GYD',
  'HKD',
  'HNL',
  'HTG',
  'HUF',
  'IDR',
  'ILS',
  'INR',
  'IQD',
  'IRR',
  'ISK',
  'JMD',
  'JOD',
  'JPY',
  'KES',
  'KGS',
  'KHR',
  'KMF',
  'KPW',
  'KRW',
  'KWD',
  'KYD',
  'KZT',
  'LAK',
  'LBP',
  'LKR',
  'LRD',
  'LSL',
  'LYD',
  'MAD',
  'MDL',
  'MGA',
  'MKD',
  'MMK',
  'MNT',
  'MOP',
  'MRU',
  'MUR',
  'MVR',
  'MWK',
  'MXN',
  'MXV',
  'MYR',
  'MZN',
  'NAD',
  'NGN',
  'NIO',
  'NOK',
  'NPR',
  'NZD',
  'OMR',
  'PAB',
  'PEN',
  'PGK',
  'PHP',
  'PKR',
  'PLN',
  'PYG',
  'QAR',
  'RON',
  'RSD',
  'RUB',
  'RWF',
  'SAR',
  'SBD',
  'SCR',
  'SDG',
  'SEK',
  'SGD',
  'SHP',
  'SLE',
  'SOS',
  'SRD',
  'SSP',
  'STN',
  'SVC',
  'SYP',
  'SZL',
  'THB',
  'TJS',
  'TMT',
  'TND',
  'TOP',
  'TRY',
  'TTD',
  'TWD',
  'TZS',
  'UAH',
  'UGX',
  'USD',
  'USN',
  'UYI',
  'UYU',
  'UYW',
  'UZS',
  'VED',
  'VES',
  'VND',
  'VUV',
  'WST',
  'XAF',
  'XAG',
  'XAU',
  'XBA',
  'XBB',
  'XBC',
  'XBD',
  'XCD',
  'XDR',
  'XOF',
  'XPD',
  'XPF',
  'XPT',
  'XSU',
  'XTS',
  'XUA',
  'XXX',
  'YER',
  'ZAR',
  'ZMW',
  'ZWG'
]
