export const authTid = {
    login: 'csp-login',
};

export const userTid = {
    avatar: 'user-avatar'
};

export const collapseCardTid = {
    footerButton: 'collapse-card-footer-button'
}

export const navigationTid = {
    contactSupport: 'header-contact-support',
};

export const contactFormTid = {
    prefix: 'contact',
    severity: 'contact-severity',
    next: 'contact-next',
    submit: 'contact-submit',
};

export const caseListTid = {
    caseIdPrefix: 'case-id'
};

export const caseDetailTid = {
    prefix: 'case-detail',
    collapseCard: 'case-detail-collapse-card',
}
