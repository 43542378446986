import React from 'react';
import { useBreadcrumbItems } from './hook';
import { useNavigate } from 'react-router-dom';
import {
  Breadcrumb,
  mergeClasses,
  BreadcrumbItem,
  BreadcrumbDivider,
  BreadcrumbButton,
} from '@fluentui/react-components';
import { useStyles } from './style';

interface IProps {
  className?: string | undefined;
}

const BreadcrumbComponent: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const breadcrumbItems = useBreadcrumbItems();

  const jumpTo = React.useCallback((path: string) => () => {
    navigate(path);
  }, [navigate]);

  return (
    <Breadcrumb size='large' className={mergeClasses(classes.container, props.className)}>
      {breadcrumbItems.map((item, index) => (
        <React.Fragment key={`breadcrumb-${index}`}>
          {!!index && <BreadcrumbDivider />}
          <BreadcrumbItem>
            <BreadcrumbButton onClick={jumpTo(item.path)} current={index === breadcrumbItems.length - 1}>
              {item.icon && <item.icon fontSize={24} />}{item.name}
            </BreadcrumbButton>
          </BreadcrumbItem>
        </React.Fragment>
      ))}
    </Breadcrumb>
  );
};

export default BreadcrumbComponent;