import React from 'react';
import Warning from '@assets/Warning.png';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';

const ErrorFallback = () => {
  const { t } = useTranslation('common');

  return (
    <div className='error-boundary'>
      <p className='error-boundary-title'>{t('PageErrorTitle')}</p>
      <img className='error-boundary-img' src={Warning} alt='Warning' />
      <pre className='error-boundary-description'>{t('PageErrorDesc')}</pre>
    </div>
  );
};

interface IProps {
  children: React.ReactNode;
}

const ErrorBoundaryWrapper: React.FC<IProps> = ({ children }) => (
  <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>
);

export default ErrorBoundaryWrapper;