import { postData, fetchData } from './httpClient'

interface IExchangeTokenResquest {
  AADToken: string;
  UserName: string;
  Email: string;
}

export const exchangeToken = async (params: IExchangeTokenResquest): Promise<void> => {
  await postData('/api/v2/UserToken/FetchXandrToken', { ...params });
};

interface IFetchUserInfoResponse {
  lastName: string;
  firstName: string;
  cspUserId: string;
  cspUserCompanyName: string;
}

export const fetchUserInfo = async (): Promise<IFetchUserInfoResponse> => {
  const res = await fetchData('/api/v2/CSPData/CSPUserInfo') as IFetchUserInfoResponse;
  return {
    ...res,
    cspUserId: res.cspUserId || '',
    cspUserCompanyName: res.cspUserCompanyName || '',
  };
};

export const userSignOut = async (): Promise<void> => {
  await fetchData('/api/v2/UserToken/CSPSignOut');
}
