import React from 'react';
import { useMsal } from '@azure/msal-react';
import { useSelector, useDispatch } from 'react-redux';
import { useAvatarStyles, useContentStyles } from './style';
import { useErrorHandler } from '@hooks/useError';
import { resetUserInfo } from '@store/userSlice';
import { useTranslation } from 'react-i18next';
import { userTid } from '@constants/testId.ts';
import { userSignOut } from '@services/user';
import { RootState } from '@store/store';
import {
  Link,
  Body1,
  Avatar,
  Divider,
  Popover,
  Caption1,
  PopoverTrigger,
  PopoverSurface,
} from '@fluentui/react-components'

const UserContent: React.FC = () => {
  const user = useSelector((state: RootState) => state.user);
  const contentClasses = useContentStyles();
  const { t } = useTranslation('common');
  const { userName, userEmail } = user;
  const handleErr = useErrorHandler();
  const dispatch = useDispatch();
  const { instance } = useMsal();

  const [loading, setLoading] = React.useState(false);

  const signOut = React.useCallback(async () => {
    try {
      setLoading(true);
      await userSignOut();
      await instance.logoutRedirect();
      dispatch(resetUserInfo());
    } catch (e) {
      handleErr(t('SignOutError'), e);
    } finally {
      setLoading(false);
    }
  }, [t, handleErr, dispatch, instance]);

  return (
    <>
      <div className={contentClasses.userContent}>
        <Avatar color='colorful' size={48} name={userName} />
        <div className={contentClasses.userInfo}>
          <Body1>{userName}</Body1>
          <Caption1>{userEmail}</Caption1>
        </div>
      </div>
      <Divider className={contentClasses.divider} />
      <Link onClick={signOut} disabled={loading}>{t('SignOut')}</Link>
    </>
  );
};

const UserAvatar: React.FC = () => {
  const user = useSelector((state: RootState) => state.user);
  const avatarClasses = useAvatarStyles();
  const { userName } = user;

  return (
    <Popover trapFocus positioning={'below-end'}>
      <PopoverTrigger disableButtonEnhancement>
        <div className={avatarClasses.avatarContainer}>
          <Avatar aria-label='user-avatar' color='colorful' data-testid={userTid.avatar} size={32} name={userName} />
        </div>
      </PopoverTrigger>
      <PopoverSurface>
        <UserContent />
      </PopoverSurface>
    </Popover>
  );
};

export default UserAvatar;