import React from 'react';
import Router from './router';
import { logger } from '@services/logger';
import { useDispatch } from 'react-redux';
import { useMsal } from '@azure/msal-react';
import { setUserInfo } from '@store/userSlice';
import { fetchCsrfToken } from '@services/security';
import { Spinner } from '@fluentui/react-components';
import { InteractionStatus } from '@azure/msal-browser';
import { exchangeToken, fetchUserInfo } from '@services/user';

const App: React.FC = () => {
  const dispatch = useDispatch();
  const { instance, inProgress } = useMsal();
  const [loading, setLoading] = React.useState(true);

  // Fetch user info
  const getUserInfo = React.useCallback(async () => {
    // 1. Check if user is authenticated from AAD
    const accounts = instance.getAllAccounts();
    if (!accounts.length) {
      logger.error('No account from msal found.');
      throw new Error('No account from msal found.');
    }

    logger.info('CSP gets a signed user');
    const { homeAccountId, name = '', username, idToken = '' } = accounts[0];
    // 2. Exchange token from Xandr side
    await exchangeToken({ AADToken: idToken, UserName: name, Email: username });

    // 3. Fetch csrf token & user info from Xandr side
    // TODO: return CSRF token from exchangeToken API
    const [userInfoData] = await Promise.all([fetchUserInfo(), fetchCsrfToken()]);
    const userInfo = {
      userId: homeAccountId,
      userName: name,
      userEmail: username,
      userCSPId: userInfoData.cspUserId,
      companyName: userInfoData.cspUserCompanyName,
    };

    logger.updateUser(userInfo.userCSPId);
    // TODO: need to update using the CSPUserId as userId from userInfo is not readable and cannot associate with the real user in CSPUser at all.
    logger.info('CSP gets a signed user info');

    // 4. Set user info to redux store
    dispatch(setUserInfo(userInfo));
  }, [dispatch, instance]);

  const init = React.useCallback(async () => {
    if (inProgress !== InteractionStatus.None) {
      return;
    }
    try {
      setLoading(true);
      await getUserInfo();
    } catch (err) {
      console.error('Init user info error.', err);
      instance.clearCache();
    } finally {
      setLoading(false);
    }
  }, [getUserInfo, inProgress, instance]);

  React.useEffect(() => {
    init();
  }, [init]);

  return loading ? <Spinner size='extra-small' style={{ height: '100%' }} /> : <Router />;
};

export default App;