import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { API_BASE_URL } from '@constants/env';
import { csrfToken } from './security';
import { logger } from './logger';

const api_name_error_placeholder = 'error_null_url';
interface IAxiosConfig extends InternalAxiosRequestConfig {
  metadata: {
    disableLog?: boolean,
    requestStartTime: Date,
    requestEndTime?: Date,
  }
}

const apiClient = axios.create({
  withCredentials: true,
  baseURL: API_BASE_URL,
  timeout: 10000,
  headers: {
    post: {
      'Content-Type': 'application/json',
    }
  }
});

apiClient.interceptors.request.use(
  (config) => {
    const metaData = (config as IAxiosConfig).metadata;
    (config as IAxiosConfig).metadata = { ...metaData, ...{ requestStartTime: new Date() } }
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (response: AxiosResponse) => {
    const metaData = (response.config as IAxiosConfig).metadata;
    metaData.requestEndTime = new Date();
    if (!metaData?.disableLog) {
      const apiName = response.config.url || api_name_error_placeholder;
      logger.perfInfo(apiName, metaData.requestStartTime, metaData.requestEndTime);
      logger.info(`Request:${apiName}, Result:Suceeded`);
    }
    return response.data
  },
  async (error: AxiosError) => {
    const metaData = (error.config as IAxiosConfig).metadata;
    if (!metaData?.disableLog) {
      const apiName = error.config?.url || api_name_error_placeholder;
      logger.error(`Request:${apiName}, Error:${JSON.stringify(error)}`);
      logger.info(`Request:${apiName}, Result:Failed`);
    }

    if (error.response?.status === 400) {
      const errorData = error.response?.data;
      return Promise.reject(new Error(typeof errorData === 'string' ? errorData : 'Bad request.'));
    }

    // Check if the error response is Unauthorized (HTTP 401)
    if (error.response?.status === 401) {
      return Promise.reject(new Error('Unauthorized access.'));
    } 

    return Promise.reject(error);
  }
);

const fetchData = (url: string, config: AxiosRequestConfig = {}): Promise<unknown> => {
  return apiClient.get(url, config);
};

const postData = (url: string, data: unknown, config: AxiosRequestConfig = {}): Promise<unknown> => {
  const tempConfig = {
    ...config,
    headers: {
      'X-CSP-XSRF-TOKEN': csrfToken,
      ...config.headers,
    }
  };
  return apiClient.post(url, data, tempConfig);
};

const postDataWithoutLog = (url: string, data: unknown, config: AxiosRequestConfig = {}): Promise<unknown> => {
  return postData(url, data, {
    ...config, ...{
      metadata: {
        disableLog: true,
      }
    }
  });
};

export { fetchData, postData, postDataWithoutLog };
