import React from 'react';
import { ToastContext } from '@contexts/toastContext';
import {
  Toast,
  useId,
  Toaster,
  ToastTitle,
  ToastIntent,
  useToastController,
} from '@fluentui/react-components';
import { useStyles } from './style';


const ToastProvider = ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles();
  const toasterId = useId('toaster');
  const { dispatchToast } = useToastController(toasterId);

  const notify = React.useCallback((message: string, intent?: ToastIntent) =>
    dispatchToast(
      <Toast>
        <ToastTitle>{message}</ToastTitle>
      </Toast>,
      { intent: intent || 'info' }
    ), [dispatchToast]);

  return (
    <ToastContext.Provider value={notify}>
      <Toaster className={classes.toast} toasterId={toasterId} />
      {children}
    </ToastContext.Provider>
  );
};

export default ToastProvider;