import { useToast } from './useToast';

export const useErrorHandler = () => {
  const toast = useToast();

  return (prefix: string, error: unknown) => {
    if (error instanceof Error) {
      toast(`${prefix}: ${error.message}`, 'error');
    } else if (typeof error === 'string') {
      toast(`${prefix}: ${error}`, 'error');
    } else {
      toast(`${prefix}.`, 'error');
    }
  }
};